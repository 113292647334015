import { Text, View } from 'react-native'
import React, { Component } from 'react'

export default class Default extends Component {
    render() {
        return (
            <View>
                <Text>Default</Text>
            </View>
        )
    }
}